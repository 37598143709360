import { Component } from '@angular/core';
import { LocalizationService } from '@client-zone/utils';

@Component({
  selector: 'czone-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  constructor(public localizationService: LocalizationService) {}

  openGdprRequest(): void {
    window.open('../../../assets/pdf/zadost_GDPR_UCED.pdf', '_blank');
  }
}
