import { Injectable, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, filter, map, Subject, takeUntil } from 'rxjs';
import { PrimaryRoute } from '../model/primary-route.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  primaryRouteData$ = new BehaviorSubject<PrimaryRoute | undefined>(undefined);
  destroying$ = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        takeUntil(this.destroying$),
        map((data) => (data as RoutesRecognized).state.root.firstChild?.data as PrimaryRoute)
      )
      .subscribe((data) => {
        this.primaryRouteData$.next(data);
      });
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
