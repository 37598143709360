<div class="account-info czone-flex-column" (click)="$event.stopPropagation()">
  <div class="account-icon"><mat-icon>account_circle</mat-icon></div>
  <div class="account-name">{{ activeAccount?.name }}</div>
  <div class="account-username">{{ activeAccount?.username }}</div>
  <div
    class="business-relationship"
    *ngIf="businessRelationshipDataService.activeRelationship$ | async as activeRelationship"
  >
    <ng-container *ngIf="businessRelationshipDataService.allRelationships$ | async as allRelationships">
      <mat-form-field appearance="fill" *ngIf="activeRelationship.id.length > 0" [subscriptSizing]="'dynamic'">
        <mat-select
          (selectionChange)="onBusinessRelationshipSelected($event, allRelationships)"
          [value]="activeRelationship?.id"
        >
          <mat-option *ngFor="let item of allRelationships" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</div>

<div class="divider"></div>

<button color="secondary" mat-menu-item [routerLink]="'user-settings'">
  <mat-icon class="menu-icon">settings</mat-icon><span>{{ 'userSession.settings' | transloco }}</span>
</button>
<button color="secondary" class="last-item" mat-menu-item (click)="logout()">
  <mat-icon class="menu-icon">logout</mat-icon><span>{{ 'userSession.logout' | transloco }}</span>
</button>
