import { Component, Input } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { BusinessRelationshipDataService } from '@client-zone/data-access';

@Component({
  selector: 'czone-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  @Input() drawer: MatDrawer | undefined;
  @Input() drawerMode: MatDrawerMode | undefined;

  constructor(public businessRelationshipDataService: BusinessRelationshipDataService) {}

  toggleDrawer(): void {
    this.drawer?.toggle();
    // Emit resize event after animation ends
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  }

  goToSettings(): void {}

  openGuide(): void {
    window.open('../../../assets/pdf/navod_zona_klienta.pdf', '_blank');
  }
}
