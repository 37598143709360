<div class="czone-main-spacing">
  <h1 class="title">{{ 'legalInformation.title' | transloco }}</h1>

  <div class="legal-information-container">
    <section>
      <h2>{{ 'legalInformation.mainOffice' | transloco }}</h2>
      <p>Sokolovská 675/9</p>
      <p>186 00 Praha 8</p>
    </section>
    <section>
      <h2>{{ 'legalInformation.companyAddress' | transloco }}</h2>
      <p>AC Piano</p>
      <p>Lidická 2331/6a</p>
      <p>370 01 České Budějovice</p>
    </section>
    <section>
      <h2>{{ 'legalInformation.identificationData' | transloco }}</h2>
      <p>{{ 'legalInformation.companyName' | transloco }}: UCED s.r.o.</p>
      <p>{{ 'legalInformation.cin' | transloco }}: 06403981</p>
      <p>{{ 'legalInformation.vatId' | transloco }}: CZ06403981 Právnická osoba</p>
      <p>{{ 'legalInformation.commercialRegister' | transloco }}: Městský soud v Praze</p>
      <p>{{ 'legalInformation.partition' | transloco }}: C 281563</p>
      <p>{{ 'legalInformation.companyRepresentative' | transloco }}: Richard Holešinský, jednatel</p>
    </section>
  </div>
</div>
