import { Component } from '@angular/core';
import { LocalizationService } from '@client-zone/utils';
import { combineLatest, map } from 'rxjs';

@Component({
  selector: 'czone-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent {
  languageSelectionViewModel$ = combineLatest([
    this.localizationService.languages$,
    this.localizationService.selectedLanguage$,
  ]).pipe(
    map(([languages, selectedLanguage]) => ({
      languages: languages,
      selectedLanguage: selectedLanguage,
    }))
  );

  constructor(public localizationService: LocalizationService) {}
}
