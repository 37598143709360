import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private _dialog: MatDialog) {}

  openDialog<T>(dialog: ComponentType<T>, config: MatDialogConfig): MatDialogRef<T, any> {
    return this._dialog.open(dialog, config);
  }

  closeDialog(): void {
    this._dialog.closeAll();
  }
}
