import { Component, Input } from '@angular/core';
import { BusinessRelationship } from '@client-zone/data-access';

@Component({
  selector: 'czone-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent {
  @Input() activeRelationship: BusinessRelationship | undefined;
}
