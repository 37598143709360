import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalizationService } from '@client-zone/utils';

@Injectable()
export class InternationalizationHeaderInterceptor implements HttpInterceptor {
  constructor(private localizationService: LocalizationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const modifiedReq = request.clone({
      headers: request.headers.set('Language', this.localizationService.selectedLanguage$.getValue()),
    });
    return next.handle(modifiedReq);
  }
}
