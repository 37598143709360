import { Injectable } from '@angular/core';
import { FileResponse } from '../api-client.generated';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  download(content: FileResponse, fileName?: string): void {
    if (content.data) {
      const blob = new Blob([content.data], { type: content.data.type });
      const url = URL.createObjectURL(blob);
      this.simulateDownloadFromUrl(url, fileName ?? 'undefined');
    }
  }

  simulateDownloadFromUrl(url: string, fileName: string): void {
    // Browsers that support HTML5 download attribute
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
