import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { API_URL, APP_FEATURES, AppConfig, Feature } from '@client-zone/data-access';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

fetch('assets/app-config.json')
  .then((response: Response) => response.json())
  .then((config: AppConfig) => {
    const protectedResourceMap = new Map<string, Array<string>>().set(config.apiGatewayUrl, config.scopes);
    const msalConfig: Configuration = {
      auth: {
        clientId: config.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: config.b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [config.b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/user-information', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "SessionStorage" is more secure, but "LocalStorage" gives you SSO between tabs.
        storeAuthStateInCookie:
          window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: { loggerOptions: { loggerCallback() {}, logLevel: LogLevel.Verbose, piiLoggingEnabled: false } },
    };

    // TODO
    const features: Feature[] = [
      { id: config.features.userInformation.id, enabled: config.features.userInformation.enabled },
      { id: config.features.customerRequests.id, enabled: config.features.customerRequests.enabled },
      { id: config.features.electricityDistribution.id, enabled: config.features.electricityDistribution.enabled },
    ];

    platformBrowserDynamic([
      { provide: API_URL, useValue: config.apiGatewayUrl },
      { provide: APP_FEATURES, useValue: features },
      { provide: MSAL_INSTANCE, useValue: new PublicClientApplication(msalConfig) },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useValue: { interactionType: InteractionType.Redirect, protectedResourceMap },
        deps: [API_URL],
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: { interactionType: InteractionType.Redirect, authRequest: { scopes: [...config.scopes] } },
      },
    ])
      .bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((err) => console.error('main.ts ERROR ' + err));
  });
