<div class="czone-main-spacing">
  <h1 class="title">{{ 'contacts.title' | transloco }}</h1>

  <div class="contacts-container">
    <section>
      <h2>{{ 'contacts.userSupport' | transloco }}</h2>
      <span>{{ 'contacts.email' | transloco }}: <a href="mailto:info@zonaklienta.cz">info&#64;zonaklienta.cz</a></span>
    </section>
    <section>
      <h2>{{ 'contacts.techSuppport' | transloco }}</h2>
      <span
        >{{ 'contacts.email' | transloco }}:
        <a href="mailto:podpora@zonaklienta.cz">podpora&#64;zonaklienta.cz</a></span
      >
    </section>
    <!-- <section>
      <h2>Telefon</h2>
      <p></p>
    </section> -->
  </div>
</div>
