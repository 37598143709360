export class Language {
  static readonly cs: Language = {
    value: 'cs',
    translationId: 'czech',
    viewValue: 'Česky',
  };

  static readonly en: Language = {
    value: 'en',
    translationId: 'english',
    viewValue: 'English',
  };

  value!: string;
  translationId!: string;
  viewValue!: string;

  static getAll(): Language[] {
    return [this.cs, this.en];
  }

  static getDefault(): Language {
    return this.cs;
  }
}
