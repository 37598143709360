import { Component, Inject, Input } from '@angular/core';
import { PrimaryRoute, NavigationService, BusinessRelationship, APP_FEATURES, Feature } from '@client-zone/data-access';
import { PrimaryRoutes } from '../../routes';

@Component({
  selector: 'czone-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() activeRelationship: BusinessRelationship | undefined;

  routes: PrimaryRoute[] | undefined;

  constructor(public navigationService: NavigationService, @Inject(APP_FEATURES) private appFeatures: Feature[]) {
    this.routes = PrimaryRoutes.getAll().filter(
      (route) => this.appFeatures.find((feature) => feature.id === route.id)?.enabled
    );
  }
}
