import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { LocalizationService } from './localization.service';

export interface MonthData {
  monthIndex: number;
  year: number;
  monthName: string | undefined;
  from: Date;
  to: Date;
  pastCurrentMonth?: boolean | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class DateUtilsService {
  formatter: Intl.DateTimeFormat | undefined;

  constructor(private localizationService: LocalizationService) {
    this.localizationService.selectedLanguage$.pipe(take(1)).subscribe({
      next: (language: string) => {
        this.formatter = new Intl.DateTimeFormat(language, { month: 'short' });
      },
    });
  }

  getMonthList(count: number): MonthData[] {
    const monthList: MonthData[] = [];
    const yearStart = new Date().getFullYear();
    const monthStart = 11;
    const date = new Date(yearStart, monthStart);

    for (let x = 0; x < count; x++) {
      const currentDate = new Date(date);
      const currentDatePlusMonth = new Date(date);

      monthList.push({
        from: currentDate,
        to: new Date(currentDatePlusMonth.setMonth(currentDatePlusMonth.getMonth() + 1)),
        monthIndex: currentDate.getMonth(),
        monthName: this.formatter?.format(currentDate),
        year: currentDate.getFullYear(),
        pastCurrentMonth:
          currentDate?.getTime() >= new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
      });

      date.setMonth(date.getMonth() - 1);
    }

    return monthList.reverse();
  }
}
