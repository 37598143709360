import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export type AlertType = 'error' | 'warning' | 'success';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private _snackBar: MatSnackBar) {}

  alert(config: { message: string; type?: AlertType; duration?: number; action?: string }): void {
    this._snackBar.open(config.message ?? 'Undefined error message', config.action, {
      duration: config.duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: config.type ? [this.getAlertClass(config.type)] : undefined,
    });
  }

  getAlertClass(type: AlertType): string {
    switch (type) {
      case 'success':
        return 'success-alert';
      case 'error':
        return 'error-alert';
      case 'warning':
        return 'warning-alert';
    }
  }
}
