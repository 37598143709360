import { Component } from '@angular/core';

@Component({
  selector: 'czone-privacy-policy-cs',
  template: `<section>
      <h2 class="secondary-title">
        INFORMACE KE ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ <br />
        pro zákazníky a obchodní partnery
      </h2>
    </section>

    <section>
      <h3 class="disclaimer">ÚČINNÉ OD [1.8.2021]</h3>
      <p>Vážení zákazníci a obchodní partneři,</p>
      <p>
        cílem tohoto dokumentu je seznámit Vás jako subjekty údajů se základními zásadami nakládání s Vašimi osobními
        údaji, které poskytujete společnosti UCED (dále jen „Společnost“ nebo „Správce osobních údajů“), a poskytnout
        Vám informaci o tom, jak Společnost tyto osobní údaje zpracovává.
      </p>
      <p>
        Společnost Vás tímto dokumentem informuje o tom, v jakém rozsahu a pro jaký účel jsou Vaše osobní údaje
        zpracovávány, kdo Vaše osobní údaje zpracovává, komu mohou být Vaše osobní údaje zpřístupněny, jaké jsou lhůty
        pro jejich uchovávání, jaká jsou Vaše práva v souvislosti se zpracováním Vašich osobních údajů a zda v
        souvislosti se zpracováním Vašich osobních údajů dochází k jejich automatizovanému zpracování včetně
        profilování.
      </p>
      <p>Tento dokument můžete také najít na webu <a href="https://www.uced.cz" target="_blank">www.uced.cz</a>.</p>
    </section>

    <section>
      <h2>VÝKLAD ZÁKLADNÍ POUŽÍVANÉ TERMINOLOGIE</h2>
      <p>
        Pro Vaši snadnější orientaci v oblasti zpracování Vašich osobních údajů níže uvádíme terminologický výklad
        základních pojmů používaných Nařízením Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v
        souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů (dále jen „<strong>GDPR</strong>”):
      </p>
      <ul>
        <li>
          <strong>Osobní údaj:</strong> Osobním údajem se dle GDPR rozumí veškeré informace o identifikované nebo
          identifikovatelné fyzické osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či
          nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační
          údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické,
          psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.
        </li>
        <li>
          <strong>Subjekt údajů:</strong> Subjekt údajů znamená identifikovanou nebo identifikovatelnou fyzickou osobu;
          v kontextu tohoto dokumentu se jedná o fyzickou osobu, jejíž osobní údaje jsou ze strany Správce osobních
          údajů zpracovávány v souladu s tímto dokumentem. Subjektem údajů je proto výlučně fyzická osoba, jíž se
          osobní. údaje týkají
        </li>
        <li>
          <strong>Zpracování osobních údajů:</strong> Jakákoliv operace nebo soubor operací s osobními údaji nebo
          soubory osobních údajů, který je prováděn pomocí či bez pomoci automatizovaných postupů, jako je shromáždění,
          zaznamenání, uspořádání, strukturování, uložení, přizpůsobení nebo pozměnění, vyhledání, nahlédnutí, použití,
          zpřístupnění přenosem, šíření nebo jakékoliv jiné zpřístupnění, seřazení či zkombinování, omezení, výmaz nebo
          zničení.
        </li>
        <li>
          <strong>Processor of personal data:</strong> The entity that processes your personal data. The Controller is
          entitled to delegate the processing of your personal data to other processors.
        </li>
        <li><strong>Recipient of personal data:</strong> The entity to which your personal data are disclosed.</li>
      </ul>
    </section>

    <section>
      <h2>ZÁKLADNÍ ZÁSADY NAKLÁDÁNÍ S VAŠIMI OSOBNÍMI ÚDAJI</h2>
      <p>
        Vaše osobní údaje jsou zpracovávány tak, aby bylo zabráněno neoprávněnému přístupu k Vašim osobním údajům či
        jejich neoprávněnému zpracování.
      </p>
      <p>Nakládání s Vašimi osobními údaji se řídí následujícími zásadami:</p>
      <ul>
        <li>
          <strong>ZÁSADA PŘESNOSTI, ZÁKONNOSTI, KOREKTNOSTI, TRANSPARENTNOSTI:</strong> Správce osobních údajů spravuje
          Vaše osobní údaje přesně. Správce osobních údajů zpracovává Vaše osobní údaje na základě právního důvodu,
          transparentně a korektně.
        </li>
        <li>
          <strong>ZÁSADA OMEZENÍ ÚČELU ZPRACOVÁNÍ:</strong> Osobní údaje jsou shromažďovány pro určité a legitimní účely
          a nesmějí být zpracovávány způsobem neslučitelným s těmito účely.
        </li>
        <li>
          <strong>ZÁSADA MINIMALIZACE:</strong> Osobní údaje jsou přiměřené a relevantní ve vztahu k účelu, pro který
          jsou zpracovávány.
        </li>
        <li>
          <strong>OMEZENÁ DOBA UCHOVÁVÁNÍ VAŠICH OSOBNÍCH ÚDAJŮ:</strong> Osobní údaje jsou uloženy ve formě umožňující
          identifikaci subjektu údajů jen po nezbytnou dobu pro dané účely, pro které jsou zpracovávány.
        </li>
        <li><strong>TECHNICKÉ A ORGANIZAČNÍ ZABEZPEČENÍ VAŠICH OSOBNÍCH ÚDAJŮ</strong></li>
      </ul>
    </section>

    <section>
      <h2>1 PRÁVNÍ ÚPRAVA NAKLÁDÁNÍ S VAŠIMI OSOBNÍMI ÚDAJI</h2>
      <p>
        Osobní údaje jsou zpracovávány v souladu s GDPR, které je přímo použitelné v členských státech Evropské unie,
        tj. rovněž v České republice.
      </p>
    </section>

    <section>
      <h2>2 SPRÁVCE OSOBNÍCH ÚDAJŮ</h2>
      <p>
        Správcem Vašich osobních údajů je <strong>Společnost</strong>. <br />
        <strong> Kontaktní údaje na Správce osobních údajů: </strong>
        <a href="mailto:jana.jakubcova@uced.cz">jana.jakubcova&#64;uced.cz</a><strong>, 724117296 </strong><br />
        Společnost nejmenovala pověřence pro ochranu osobních údajů.
      </p>
    </section>

    <section>
      <h2>3 PRÁVNÍ ZÁKLAD, ÚČEL A DOBA ZPRACOVÁNÍ VAŠICH OSOBNÍCH ÚDAJŮ</h2>
      <p>
        V souladu s čl. 6 GDPR mohou být Vaše osobní údaje zpracovávány výlučně na základě některého z následujících
        zákonných důvodů:
      </p>
      <ol>
        <li>Vámi uděleného souhlasu se zpracování osobních údajů.</li>
        <li>
          Zpracování je nezbytné pro plnění povinností ze smlouvy, které jste smluvní stranou, nebo pro provedení
          opatření přijatých před uzavřením smlouvy na žádost subjektu údajů.
        </li>
        <li>Zpracování je nezbytné pro splnění právní povinnosti.</li>
        <li>Zpracování je nezbytné pro ochranu životně důležitých zájmů subjektu údajů nebo jiné fyzické osoby.</li>
        <li>
          Zpracování je nezbytné pro účely oprávněných zájmů příslušného správce či třetí strany, kromě případů, kdy
          před těmito zájmy mají přednost zájmy nebo základní práva a svobody subjektu údajů vyžadující ochranu osobních
          údajů.
        </li>
      </ol>
      <p>
        Vaše osobní údaje ukládáme po dobu trvání smluvního vztahu a dále po dobu, která je vyžadována a uvedena v
        příslušných právních předpisech.
      </p>
      <p>
        Vaše osobní údaje Společnost zpracovává zejména pro účely uzavření smlouvy se Společností, a dále pro účely
        plnění závazků Společnosti. V těchto případech není pro zpracování osobních údajů třeba Vašeho souhlasu.
      </p>
      <p>
        V případech, kdy Společnost neprovádí zpracování osobních údajů z důvodu plnění smluvní nebo zákonné povinnosti
        Společnosti, vyžaduje se zpracováním osobních údajů Váš souhlas. Jedná se o zpracování osobních údajů pro
        marketingové a obchodní účely, kdy Vás Společnost informuje o produktech nabízených Společností. Tento souhlas
        je zcela dobrovolný a lze ho snadno odvolat písemně na korespondenční adrese Společnosti, přičemž odvoláním
        souhlasu není dotčena zákonnost zpracování osobních údajů před odvoláním souhlasu.
      </p>
      <p>
        Další možný účel zpracování Vaších osobních údajů, jakož i přehled dob jejich uchovávání, naleznete v tabulce
        níže:
      </p>
    </section>

    <section>
      <table>
        <thead>
          <!-- 0 -->
          <tr>
            <th>Subjekt údajů, kterého se tento důvod a účel zpracování týká</th>
            <th>kterého se tento důvod a účel zpracování týká Účel zpracování osobních údajů</th>
            <th>Právní základ zpracování osobních údajů</th>
            <th>Doba uchování osobních údajů</th>
          </tr>
        </thead>
        <tbody>
          <!-- 1 -->
          <tr>
            <td>
              <strong>
                - Zákazník <br />
                - Obchodní partner
              </strong>
            </td>
            <td>
              Zajištění plnění povinností vyplývajících ze smluvního vztahu, jakož i zajištění právních povinností
            </td>
            <td>
              - Plnění povinností ze smlouvy <br />
              - Plnění právních povinností
            </td>
            <td>
              Ve vztahu k určitým účelům zpracování osobních údajů jsme stanovili obvyklou dobu jejich zpracování
              následovně:
              <br />
              <br />
              - Plnění povinností ze smlouvy: po dobu trvání smluvního vztahu a dále po dobu 10 let od jeho skončení
              <br />
              - Splnění právních povinností: doba stanovená zákonem pro uchovávání záznamů
            </td>
          </tr>
          <!-- 2 -->
          <tr>
            <td>
              <strong>
                - Zákazník <br />
                - Obchodní partner
              </strong>
            </td>
            <td>
              Vedení evidence příchozí a odchozí korespondence včetně zajištění životního cyklu dokumentů ve spisovnách
            </td>
            <td>
              - Plnění právních povinností <br />
              - Ochrana oprávněných zájmů Správce osobních údajů
            </td>
            <td>Zejm. dle skartační lhůty stanovené právními předpisy</td>
          </tr>
          <!-- 3 -->
          <tr>
            <td>
              <strong>
                - Zákazník <br />
                - Obchodní partner
              </strong>
            </td>
            <td>Kamerové záznamy k zajištění ochrany života, zdraví a majetku</td>
            <td>- Ochrana oprávněných zájmů Správce osobních údajů</td>
            <td>
              Kamerové záznamy uchováváme po dobu 60 dní, příp. po dobu delší, je-li to nezbytné pro ochranu oprávněných
              zájmů správce osobních údajů (např. v příslušném soudním či správním řízení)
            </td>
          </tr>
          <!-- 4 -->
          <tr>
            <td>
              <strong>
                - Zákazník <br />
                - Obchodní partner
              </strong>
            </td>
            <td>
              Uplatnění nároků či právní obrana Správce osobních údajů v soudních, správních či jiných obdobných řízení
            </td>
            <td>- Ochrana oprávněných zájmů Správce osobních údajů</td>
            <td>
              Pro tento účel Vaše osobní údaje zpracováváme po dobu trvání promlčecí doby (zpravidla nejvýše 10 let) a
              dále po dobu trvání soudních, správních či jiných obdobných řízení.
            </td>
          </tr>
          <!-- 4 -->
          <tr>
            <td>
              <strong>
                - Zákazník <br />
                - Obchodní partner
              </strong>
            </td>
            <td></td>
            <td>- Souhlas Subjektu údajů se zpracováním osobních údajů</td>
            <td>
              Po dobu, která je nezbytná k uvedenému účelu zpracování.
              <br />
              <br />
              Zpravidla pak do doby odvolání souhlasu Subjektem údajů, pokud Správce osobních údajů nemá ke zpracování
              těchto osobních údajů jiný zákonem stanovený důvod.
              <br />
              <br />
              Souhlas se zpracováním osobních údajů je možné kdykoliv odvolat.
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section>
      <h2>4 ROZSAH ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h2>
      <p>Společnost zpracovává následující osobní údaje:</p>
      <ul>
        <li>
          <b>Identifikační údaje:</b> jméno, příjmení, rodné číslo, místo a datum narození, adresa bydliště, rodinný
          stav, státní příslušnost, fotografie, druh a číslo dokladu totožnosti
        </li>
        <li><b>Kontaktní údaje:</b> doručovací adresa, mobil, e-mail</li>
        <li>
          <b>Veřejně dostupné údaje:</b> Údaje veřejně dostupné na internetu z obchodního, živnostenského, insolvenčního
          či jiného obdobného rejstříku aj.
        </li>
        <li><b>Údaje získané vzájemnou komunikací</b></li>
        <li><b>Bankovní spojení</b></li>
        <li>
          <b>Údaje k zajištění bezpečnosti v budově:</b> Tyto údaje jsou získávány za účelem ochrany oprávněných zájmů
          Společnosti. Jedná se zejména o monitoring prostoru či okolí budov užívaných Společností.
        </li>
        <li><b>Případně další osobní údaje uvedené v rámci Vašeho souhlasu</b></li>
      </ul>
      <p>
        U podnikající fyzické osoby Společnost zpracovává též její obchodní firmu, odlišující dodatek nebo další
        označení, místo podnikání, identifikační číslo.
      </p>
    </section>

    <section>
      <h2>5 ZDROJE OSOBNÍCH ÚDAJŮ</h2>
      <p>Osobní údaje získáváme výhradně od Vás, a to v souvislosti s jednáním o uzavření smlouvy.</p>
      <p>
        Osobní údaje od Vás získáváme rovněž v průběhu trvání smlouvy, a to v případě, že se Vámi poskytnuté osobní
        údaje změnily a Vy plníte ve vztahu ke Společnosti informační povinnost.
      </p>
      <p>
        Vaše osobní údaje zpracováváme zejména z informací Vámi poskytnutých v rámci vzájemné komunikace, informací
        veřejně dostupných či webových portálů.
      </p>
    </section>

    <section>
      <h2>6 ZPŘÍSTUPNĚNÍ OSOBNÍCH ÚDAJŮ TŘETÍM SUBJEKTŮM</h2>
      <p><b>Zpracovatelé a příjemci osobních údajů</b></p>
      <p>Vaše osobní údaje jsou předávány rovněž třetím subjektům.</p>
      <p>Vaše osobní údaje mohou být bez Vašeho souhlasu zpřístupněny:</p>
      <ul>
        <li>
          na písemné vyžádání veřejným orgánům, např. soudům, orgánům činným v trestním řízení, správcům daně, soudním
          exekutorům, správním orgánům apod., a to pouze v rozsahu a v souladu s obecně závaznými právními předpisy,
          osobám, které se na základě smlouvy podílejí na předmětu činnosti Společnosti, které jsou pověřeny k plnění
          smluvních a zákonných povinností Společnosti, to vše za výše uvedenými účely zpracování osobních údajů.
        </li>
      </ul>
      <p>Dalším subjektům mohou být Vaše osobní údaje poskytnuty výhradně s Vaším výslovným souhlasem.</p>
      <p>
        Správce osobních údajů zpracovává Vaše osobní údaje prostřednictvím svých zaměstnanců, přičemž současně využívá
        služeb dalších zpracovatelů, a to za současného zajištění vhodných technických a organizačních opatření, které
        zajistí dostatečnou ochranu Vašich osobních údajů.
      </p>
      <p>Pro zpracování Vašich osobních údajů využíváme rovněž služeb níže uvedených kategorií subjektů:</p>
      <ul>
        <li>bezpečnostní agentury</li>
        <li>personální agentury</li>
        <li>poskytovatel IT služeb a dodavatel software</li>
        <li>poskytovatel poštovních a kurýrních služeb</li>
        <li>poskytovatel právních služeb</li>
        <li>archivační služby</li>
        <li>jiný zplnomocněný subjekt</li>
      </ul>
    </section>

    <section>
      <h2>7 PROFILOVÁNÍ A AUTOMATIZOVANÉ ROZHODOVÁNÍ PŘI ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ</h2>
      <p>
        Společnost v rámci svých procesů nevyužívá automatizované informační systémy a profilování, tudíž nedochází k
        vyhodnocování Vašich osobních údajů.
      </p>
    </section>

    <section>
      <h2>8 VAŠE PRÁVA SOUVISEJÍCÍ SE ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ</h2>
      <p>Vaše práva týkající se zpracování Vašich osobních údajů jsou následující:</p>
      <ul>
        <li>
          <strong>právo na přístup ke svým osobním údajům dle čl. 15 GDPR</strong>
          <br />
          Každý má právo se dozvědět, zda jsou nebo nejsou jeho údaje zpracovávány - pokud ano, pak má právo získat
          přístup k těmto údajům a dále k informacím o účelech, kategoriích údajů, příjemcích, době uložení, o právu
          podat stížnost, o zdroji údajů (pokud nejsou od subjektu), že dochází k automatizovanému rozhodování a dále má
          právo získat kopii těchto údajů.
        </li>
        <li>
          <strong>právo na informace</strong>
          <br />
          Máte právo znát informace o způsobu zpracování Vašich osobních údajů, účelu a době jejich zpracování, jakož i
          zdroji, z nějž byly Vaše osobní údaje získány. V rámci práva na přístup nás můžete požádat o kopii
          zpracovávaných osobních údajů.
        </li>
        <li>
          <strong>
            právo na opravu Vašich osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování Vašich osobních údajů
            dle čl. 18 GDPR
          </strong>
          <br />
          Pokud zjistíte, že osobní údaje, které o Vás zpracováváme, jsou nepřesné, máte právo požadovat, aby je Správce
          osobních údajů bez zbytečného odkladu opravil. S přihlédnutím k účelům zpracování máte také právo na doplnění
          neúplných osobních údajů.
        </li>
        <li>
          <strong>právo na výmaz osobních údajů dle čl. 17 GDPR</strong>
          <br />
          Máte právo požadovat, aby Správce osobních údajů bez zbytečného odkladu vymazal Vaše osobní údaje, pokud bude
          splněna některá z následujících podmínek:
          <br />
          <br />
          <ul>
            <li>Vaše osobní údaje již nejsou potřeba k účelu, ke kterému byly shromážděny</li>
            <li>
              odvoláte souhlas se zpracováním Vašich osobních údajů a současně neexistuje jiný právní důvod (právní
              titul) ke zpracování Vašich osobních údajů
            </li>
            <li>
              vznesete námitku proti zpracování Vašich osobních údajů a neexistuje žádný převažující důvod pro
              zpracování Vašich osobních údajů
            </li>
            <li>Vaše osobní údaje jsou zpracovávány protiprávně</li>
            <li>výmaz Vašich osobních údajů ukládá právní předpis</li>
          </ul>
          Právo požadovat výmaz Vašich osobních údajů se neuplatní, pokud je jejich zpracování nadále nezbytné pro:
          <br />
          <br />
          <ul>
            <li>splnění právní povinnosti Správce osobních údajů</li>
            <li>archivační účely Správce osobních údajů</li>
            <li>ochranu oprávněných zájmů Správce osobních údajů</li>
            <li>zajištění nebo obhajobu právních nároků Správce osobních údajů</li>
          </ul>
        </li>
        <li>
          <strong>právo vznést námitku proti zpracování dle čl. 21 GDPR</strong>
          <br />
          Máte právo vznést námitku proti zpracování Vašich osobních údajů Správci osobních údajů a Správce osobních
          údajů dále takové údaje nezpracovává v případě, že:
          <br />
          <br />
          <ul>
            <li>
              jde o zpracování Vašich osobních údajů nezbytné pro plnění úkolu ve veřejném zájmu nebo na něm má
              oprávněný zájem Správce osobních údajů, a Správce osobních údajů neprokáže převažující zájem na zpracování
              nad Vašimi právy a svobodami
            </li>
            <li>jde o zpracování osobních údajů pro účely přímého marketingu</li>
          </ul>
        </li>
        <li>
          <strong>právo na přenositelnost údajů dle čl. 20 GDPR</strong>
          <br />
          Máte právo získat Vaše osobní údaje, které jste poskytli Správci osobních údajů, ve strukturovaném, běžně
          používaném a strojově čitelném formátu, a právo předat tyto údaje jinému správci, aniž by tomu Správce
          osobních údajů bránil. Toto právo se vztahuje na případy, kdy jsou Vaše údaje zpracovávány na základě souhlasu
          nebo smlouvy a zároveň pokud jsou údaje zpracovávány automatizovaně. Vaše osobní údaje Vám poskytneme ve
          strukturovaném, běžně používaném a strojově čitelném formátu.
        </li>
        <li>
          <strong>právo na omezení zpracování Vašich osobních údajů z důvodů stanovených právními předpisy</strong>
          (např. z důvodu protiprávnosti jejich zpracování, nepřesnosti jejich zpracování)
        </li>
        <li>
          <strong>
            právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email Správce osobních údajů
          </strong>
          <br />
          Subjekt údajů má právo odvolat svůj souhlas se zpracováním osobních údajů. Uvedené platí za předpokladu, že
          osobní údaje subjektu údajů jsou zpracovávány na základě jeho souhlasu s jejich zpracováním.
        </li>
        <li>
          <strong>právo podat stížnost k příslušnému dozorovému úřadu</strong>
        </li>
      </ul>
      <p>
        Pokud byste se domnívali, že zpracováním Vašich osobních údajů bylo porušeno či je porušováno GDPR, máte mimo
        jiné právo podat stížnost u příslušného dozorového úřadu, kterým je Úřad pro ochranu osobních údajů.
      </p>
      <p>V případě, že nedostanete možnost uplatnit některé ze svých výše uvedených práv, máte právo vznést námitku.</p>
      <p><b>Jakým způsobem lze uplatnit jednotlivá práva?</b></p>
      <p>
        Veškeré záležitosti týkající se zpracování Vašich osobních údajů můžete řešit přímo u Správce osobních údajů,
        přičemž jej můžete kontaktovat kterýmkoliv z následujících prostředků:
      </p>
      <ul>
        <li>E-mailem na: <a href="mailto:jana.jakubcova@uced.cz">jana.jakubcova&#64;uced.cz</a></li>
        <li>Písemně na adresu: UCED s.r.o., AC Piano, Lidická 2331/6a, 370 01 České Budějovice</li>
      </ul>
      <p>
        Na žádosti musí být Váš podpis úředně ověřen. Vaše práva můžete rovněž uplatnit osobně u kteréhokoli pracovníka
        Společnosti.
      </p>
      <p>
        Vaši žádost vyřídíme bez zbytečného odkladu, maximálně však do jednoho měsíce. Ve výjimečných případech, zejména
        z důvodu složitosti Vašeho požadavku, jsme oprávněni tuto lhůtu prodloužit o další dva měsíce. O takovém
        případném prodloužení a jeho zdůvodnění Vás samozřejmě budeme informovat.
      </p>
    </section>`,
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyCsComponent {}
