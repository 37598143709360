import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Feature } from '../model/app-config.model';

export const APP_FEATURES = new InjectionToken<{ id: string; isEnabled: boolean }[]>('FEATURE_CONFIG');

@Injectable({
  providedIn: 'root',
})
export class AppFeatureGuard {
  constructor(@Inject(APP_FEATURES) private appFeatures: Feature[]) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const id = next?.routeConfig?.path?.split('/')[0];
    return this.appFeatures.find((x) => x.id === id)?.enabled ?? false;
  }
}
