import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import {
  DataAccessModule,
  InternationalizationHeaderInterceptor,
  LoadingService,
  NavigationService,
} from '@client-zone/data-access';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { UserSessionComponent } from './components/user-session/user-session.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { LegalInformationComponent } from './components/legal-information/legal-information.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe, provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PrivacyPolicyCsComponent } from './components/privacy-policy/privacy-policy-cs.component';
import { PrivacyPolicyEnComponent } from './components/privacy-policy/privacy-policy-en.component';
import { LocalizationService, getCurentLocale } from '@client-zone/utils';

function initializeApp(): Promise<unknown> {
  return new Promise((resolve) => {
    resolve(true);
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    SidePanelComponent,
    MainHeaderComponent,
    NavigationComponent,
    UserSessionComponent,
    UserSettingsComponent,
    LegalInformationComponent,
    PrivacyPolicyComponent,
    ContactsComponent,
    PrivacyPolicyCsComponent,
    PrivacyPolicyEnComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: getCurentLocale() },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CZK' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternationalizationHeaderInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeApp,
      deps: [NavigationService, LoadingService, LocalizationService],
      multi: true,
    },
    provideTransloco({
      config: {
        availableLangs: ['cs', 'en'],
        defaultLang: 'cs',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataAccessModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatProgressBarModule,
    MsalModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    TranslocoPipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
