export type PowerProfileNames = 'pPlus' | 'pMinus' | 'qiPlus' | 'qcMinus' | 'qiMinus' | 'qcPlus';

export class PowerProfiles {
  static readonly pPlus: PowerProfiles = {
    value: 'pPlus',
    shortName: 'P+ [kW]',
    displayName: 'Činný výkon - odběr',
    translationId: 'powerProfiles.pPlus',
  };

  static readonly pMinus: PowerProfiles = {
    value: 'pMinus',
    shortName: 'P- [kW]',
    displayName: 'Činný výkon - dodávka',
    translationId: 'powerProfiles.pMinus',
  };

  static readonly qiPlus: PowerProfiles = {
    value: 'qiPlus',
    shortName: 'Qi+ [kVAr]',
    displayName: 'Jalový výkon induktivní - odběr',
    translationId: 'powerProfiles.qiPlus',
  };

  static readonly qcMinus: PowerProfiles = {
    value: 'qcMinus',
    shortName: 'Qc- [kVAr]',
    displayName: 'Jalový výkon kapacitní - dodávka',
    translationId: 'powerProfiles.qcMinus',
  };

  static readonly qiMinus: PowerProfiles = {
    value: 'qiMinus',
    shortName: 'Qi- [kVAr]',
    displayName: 'Jalový výkon induktivní - dodávka',
    translationId: 'powerProfiles.qiMinus',
  };

  static readonly qcPlus: PowerProfiles = {
    value: 'qcPlus',
    shortName: 'Qc+ [kVAr]',
    displayName: 'Jalový výkon kapacitní - odběr',
    translationId: 'powerProfiles.qcPlus',
  };

  static readonly unknown: PowerProfiles = {
    displayName: 'Neznámá hodnota',
    shortName: 'unknown',
    translationId: 'powerProfiles.unknown',
  };

  readonly value?: 'pPlus' | 'pMinus' | 'qiPlus' | 'qcMinus' | 'qcPlus' | 'qiMinus' | undefined;
  readonly displayName?: string;
  readonly shortName!: string;
  readonly translationId!: string;

  static getAll(): PowerProfiles[] {
    return [this.pPlus, this.pMinus, this.qiPlus, this.qcMinus, this.qiMinus, this.qcPlus];
  }

  static getConsumption(): PowerProfiles[] {
    return [this.pPlus, this.qiPlus, this.qcPlus];
  }

  static getSupply(): PowerProfiles[] {
    return [this.pMinus, this.qiMinus, this.qcMinus];
  }

  static getProfileById(profileId: string): PowerProfiles {
    return this.getAll().find((profile) => profile.value === profileId) ?? this.unknown;
  }
}
