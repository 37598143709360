import { Component } from '@angular/core';

@Component({
  selector: 'czone-privacy-policy-en',
  template: `<section>
      <h2 class="secondary-title">
        INFORMATION ON THE PROCESSING OF PERSONAL DATA <br />
        for customers and business partners
      </h2>
    </section>

    <section>
      <h3 class="disclaimer">EFFECTIVE FROM [1. 8. 2021]</h3>
      <p>Dear customers and business partners,</p>
      <p>
        The purpose of this document is to acquaint you, as data subjects, with the basic principles of handling your
        personal data that you provide to the company UCED (hereinafter referred to as the "Company" or "Data
        Controller"), and to provide you with information on how the Company processes these personal data.
      </p>
      <p>
        This document informs you about the extent and purpose of the processing of your personal data, who processes
        your personal data, to whom your personal data may be disclosed, what are the retention periods for your
        personal data, what are your rights in relation to the processing of your personal data, and whether there is
        automated processing of your personal data, including profiling, in connection with the processing of your
        personal data.
      </p>
      <p>
        This document can also be found on the website <a href="https://www.uced.cz" target="_blank">www.uced.cz</a>.
      </p>
    </section>

    <section>
      <h2>EXPLANATION OF BASIC TERMINOLOGY</h2>
      <p>
        For easier orientation in the area of processing your personal data, below is a terminological explanation of
        basic terms used by the Regulation of the European Parliament and of the Council (EU) 2016/679 on the protection
        of natural persons with regard to the processing of personal data and on the free movement of such data
        (hereinafter referred to as “<strong>GDPR</strong>”):
      </p>
      <ul>
        <li>
          <strong>Personal data:</strong> According to the GDPR, personal data means any information relating to an
          identified or identifiable natural person; an identifiable natural person is one who can be identified,
          directly or indirectly, in particular by reference to an identifier such as a name, an identification number,
          location data, an online identifier, or to one or more factors specific to the physical, physiological,
          genetic, mental, economic, cultural, or social identity of that natural person
        </li>
        <li>
          <strong>Data subject:</strong> The data subject means an identified or identifiable natural person; in the
          context of this document, it refers to a natural person whose personal data are processed by the Data
          Controller in accordance with this document. Therefore, the data subject is exclusively a natural person to
          whom the personal data relate.
        </li>
        <li>
          <strong>Processing of personal data:</strong> Any operation or set of operations which is performed on
          personal data or on sets of personal data, whether or not by automated means, such as collection, recording,
          organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
          transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or
          destruction.
        </li>
        <li>
          <strong>Zpracovatel osobních údajů:</strong> subjekt, který zpracovává Vaše osobní údaje. Správce je oprávněn
          pověřit zpracováním Vašich osobních údajů i další zpracovatele.
        </li>
        <li><strong>Příjemce osobních údajů:</strong> subjekt, kterému jsou Vaše osobní údaje poskytnuty</li>
      </ul>
    </section>

    <section>
      <h2>BASIC PRINCIPLES OF PROCESSING YOUR PERSONAL DATA</h2>
      <p>
        Your personal data is processed in a way that prevents unauthorized access to or unauthorized processing of your
        personal data.
      </p>
      <p>The handling of your personal data is guided by the following principles:</p>
      <ul>
        <li>
          <strong>PRINCIPLE OF ACCURACY, LEGALITY, FAIRNESS, AND TRANSPARENCY:</strong> The personal data controller
          manages your personal data accurately. The personal data controller processes your personal data on a legal
          basis, transparently, and fairly.
        </li>
        <li>
          <strong>PRINCIPLE OF PURPOSE LIMITATION:</strong> Personal data are collected for specific, legitimate
          purposes and may not be processed in a manner incompatible with those purposes.
        </li>
        <li>
          <strong>PRINCIPLE OF DATA MINIMIZATION:</strong> Personal data are adequate and relevant in relation to the
          purpose for which they are processed.
        </li>
        <li>
          <strong>LIMITED RETENTION PERIOD FOR YOUR PERSONAL DATA:</strong> Personal data are stored in a form that
          permits identification of data subjects for no longer than is necessary for the purposes for which the
          personal data are processed.
        </li>
        <li><strong>TECHNICAL AND ORGANIZATIONAL SECURITY OF YOUR PERSONAL DATA</strong></li>
      </ul>
    </section>

    <section>
      <h2>1 LEGAL FRAMEWORK FOR HANDLING YOUR PERSONAL DATA</h2>
      <p>
        Personal data are processed in accordance with the GDPR, which is directly applicable in the member states of
        the European Union, including the Czech Republic.
      </p>
    </section>

    <section>
      <h2>2 DATA CONTROLLER</h2>
      <p>
        The controller of your personal data is the <strong>Company</strong>. <br />
        <strong> Contact details for the Data Controller: </strong>
        <a href="mailto:jana.jakubcova@uced.cz">jana.jakubcova&#64;uced.cz</a><strong>, 724117296 </strong><br />
        The Company has not appointed a Data Protection Officer.
      </p>
    </section>

    <section>
      <h2>3 LEGAL BASIS, PURPOSE, AND DURATION OF PROCESSING YOUR PERSONAL DATA</h2>
      <p>
        In accordance with Article 6 of the GDPR, your personal data can only be processed on one of the following legal
        grounds:
      </p>
      <ol>
        <li>Consent you have given for the processing of personal data.</li>
        <li>
          Processing is necessary for the performance of a contract to which you are a party, or to take steps at your
          request before entering into a contract.
        </li>
        <li>Processing is necessary for compliance with a legal obligation.</li>
        <li>Processing is necessary to protect the vital interests of the data subject or another natural person.</li>
        <li>
          Processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third
          party, except where such interests are overridden by the interests or fundamental rights and freedoms of the
          data subject which require protection of personal data.
        </li>
      </ol>
      <p>
        Your personal data is stored for the duration of the contractual relationship and further for the period
        required and stated in the relevant legal regulations.
      </p>
      <p>
        The Company processes your personal data mainly for the purposes of concluding a contract with the Company, and
        also for fulfilling the Company's obligations. In these cases, your consent for the processing of personal data
        is not required.
      </p>
      <p>
        In cases where the Company does not process personal data due to the fulfillment of a contractual or legal
        obligation of the Company, your consent is required for the processing of personal data. This applies to the
        processing of personal data for marketing and business purposes, where the Company informs you about products
        offered by the Company. This consent is entirely voluntary and can be easily withdrawn in writing at the
        Company's mailing address, and the withdrawal of consent does not affect the lawfulness of processing based on
        consent before its withdrawal.
      </p>
      <p>
        Further possible purposes for processing your personal data, as well as an overview of their retention periods,
        can be found in the table below:
      </p>
    </section>

    <section>
      <table>
        <thead>
          <!-- 0 -->
          <tr>
            <th>Data Subject (Who it's about)</th>
            <th>Purpose of Processing Personal Data</th>
            <th>Legal Basis for Processing</th>
            <th>Retention Period of Personal Data</th>
          </tr>
        </thead>
        <tbody>
          <!-- 1 -->
          <tr>
            <td>
              <strong>
                - Customer <br />
                - Business Partner
              </strong>
            </td>
            <td>
              Ensuring fulfillment of obligations arising from the contractual relationship, as well as ensuring legal
              obligations
            </td>
            <td>
              - Fulfillment of contract obligations <br />
              - Compliance with legal obligations
            </td>
            <td>
              For certain purposes of processing personal data, we have set the usual processing time as follows:
              <br />
              <br />
              - Fulfillment of contract obligations: for the duration of the contractual relationship and then for 10
              years after its termination
              <br />
              - Compliance with legal obligations: period prescribed by law for keeping records
            </td>
          </tr>
          <!-- 2 -->
          <tr>
            <td>
              <strong>
                - Customer <br />
                - Business Partner
              </strong>
            </td>
            <td>
              Keeping records of incoming and outgoing correspondence including ensuring the document lifecycle in
              archives
            </td>
            <td>
              - Compliance with legal obligations <br />
              - Protection of legitimate interests of the Data Controller
            </td>
            <td>According to the disposal period set by legal regulations</td>
          </tr>
          <!-- 3 -->
          <tr>
            <td>
              <strong>
                - Customer <br />
                - Business Partner
              </strong>
            </td>
            <td>Camera recordings to ensure the protection of life, health, and property</td>
            <td>- Protection of legitimate interests of the Data Controller</td>
            <td>
              Camera recordings are kept for 60 days, or for a longer period if necessary to protect the legitimate
              interests of the data controller (e.g., in relevant court or administrative proceedings)
            </td>
          </tr>
          <!-- 4 -->
          <tr>
            <td>
              <strong>
                - Customer <br />
                - Business Partner
              </strong>
            </td>
            <td>
              Assertion of claims or legal defense of the Data Controller in judicial, administrative, or similar
              proceedings
            </td>
            <td>- Protection of legitimate interests of the Data Controller</td>
            <td>
              Customer, Business Partner Assertion of claims or legal defense of the Data Controller in judicial,
              administrative, or similar proceedings - Protection of legitimate interests of the Data Controller For
              this purpose, we process your personal data for the duration of the limitation period (usually up to 10
              years) and further for the duration of judicial, administrative, or similar proceedings.
            </td>
          </tr>
          <!-- 5 -->
          <tr>
            <td>
              <strong>
                - Customer <br />
                - Business Partner
              </strong>
            </td>
            <td></td>
            <td>- Consent of the Data Subject for the processing of personal data</td>
            <td>
              Po dobu, která je nezbytná k uvedenému účelu zpracování.
              <br />
              <br />
              After the period necessary for the stated purpose of processing. Typically, until the withdrawal of
              consent by the Data Subject, unless the Data Controller has another legally established reason for
              processing these personal data.
              <br />
              <br />
              Consent to the processing of personal data can be revoked at any time.
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section>
      <h2>4 SCOPE OF PERSONAL DATA PROCESSING</h2>
      <p>The Company processes the following personal data:</p>
      <ul>
        <li>
          <b>Identification data:</b> name, surname, birth number, place and date of birth, residential address, marital
          status, nationality, photograph, type and number of identity document
        </li>
        <li><b>Contact details:</b> mailing address, mobile phone, email</li>
        <li>
          <b>Publicly available data:</b> Data publicly available on the internet from trade, business, insolvency, or
          other similar registries, etc.
        </li>
        <li><b>Data obtained through mutual communication</b></li>
        <li><b>Banking details</b></li>
        <li>
          <b>Data for ensuring security in the building: </b> These data are obtained for the purpose of protecting the
          legitimate interests of the Company. This particularly includes monitoring the premises or surroundings of
          buildings used by the Company.
        </li>
        <li><b>Other personal data provided in your consent</b></li>
      </ul>
      <p>
        In the case of a self-employed individual, the Company also processes their trade name, additional identifying
        information or other designations, place of business, identification number.
      </p>
    </section>

    <section>
      <h2>5 SOURCES OF PERSONAL DATA</h2>
      <p>We exclusively obtain personal data from you, in connection with negotiations for entering into a contract.</p>
      <p>
        We also collect personal data from you during the duration of the contract, in cases where the personal data you
        have provided have changed and you fulfill an informational obligation towards the Company.
      </p>
      <p>
        We mainly process your personal data based on the information you have provided during mutual communication,
        information that is publicly available, or from web portals.
      </p>
    </section>

    <section>
      <h2>6 DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES</h2>
      <p><b>Processors and recipients of personal data</b></p>
      <p>Your personal data are also transferred to third parties.</p>
      <p>Your personal data may be disclosed without your consent:</p>
      <ul>
        <li>
          upon written request to public authorities, e.g., courts, criminal proceedings authorities, tax
          administrators, judicial executors, administrative agencies, etc., but only to the extent and in accordance
          with generally binding legal regulations, to persons who participate in the Company's activities based on a
          contract, who are tasked with fulfilling the Company's contractual and legal obligations, all for the
          above-mentioned purposes of processing personal data.
        </li>
      </ul>
      <p>Your personal data may be provided to other entities exclusively with your explicit consent.</p>
      <p>
        The personal data controller processes your personal data through its employees, while also utilizing the
        services of other processors, ensuring appropriate technical and organizational measures are in place to provide
        adequate protection for your personal data.
      </p>
      <p>We also use services from the following categories for processing your personal data:</p>
      <ul>
        <li>Security agencies</li>
        <li>Staffing agencies</li>
        <li>IT service providers and software suppliers</li>
        <li>Postal and courier service providers</li>
        <li>Legal service providers</li>
        <li>Archival services</li>
        <li>Other authorized entities</li>
      </ul>
    </section>

    <section>
      <h2>7 PROFILING AND AUTOMATED DECISION-MAKING IN PERSONAL DATA PROCESSING</h2>
      <p>
        The Company does not use automated information systems and profiling within its processes, therefore, there is
        no evaluation of your personal data.
      </p>
    </section>

    <section>
      <h2>8 YOUR RIGHTS RELATED TO THE PROCESSING OF PERSONAL DATA</h2>
      <p>Your rights regarding the processing of your personal data are as follows:</p>
      <ul>
        <li>
          <strong>Right of access to your personal data under Article 15 of the GDPR</strong>
          <br />
          Everyone has the right to know whether their data are being processed - if so, they have the right to access
          these data and to information about the purposes, categories of data, recipients, storage period, the right to
          lodge a complaint, the source of the data (if not from the subject), the occurrence of automated
          decision-making, and also the right to obtain a copy of these data.
        </li>
        <li>
          <strong>Right to information</strong>
          <br />
          You have the right to know information about the way your personal data are processed, the purpose and
          duration of their processing, as well as the source from which your personal data were obtained. As part of
          the right of access, you can request a copy of the processed personal data.
        </li>
        <li>
          <strong>
            Right to rectification of your personal data under Article 16 of the GDPR, or to restriction of processing
            of your personal data under Article 18 of the GDPR
          </strong>
          <br />
          If you discover that the personal data we process about you are inaccurate, you have the right to have the
          Data Controller correct them without undue delay. Considering the purposes of the processing, you also have
          the right to have incomplete personal data completed.
        </li>
        <li>
          <strong>Right to erasure of personal data under Article 17 of the GDPR</strong>
          <br />
          You have the right to have the Data Controller erase your personal data without undue delay if one of the
          following conditions is met:
          <br />
          <br />
          <ul>
            <li>Your personal data are no longer necessary for the purpose for which they were collected</li>
            <li>
              You withdraw consent to the processing of your personal data, and there is no other legal ground for
              processing
            </li>
            <li>
              You object to the processing of your personal data, and there are no overriding legitimate grounds for the
              processing
            </li>
            <li>Your personal data have been unlawfully processed</li>
            <li>The erasure of your personal data is required by law</li>
          </ul>
          The right to request the erasure of your personal data does not apply if their processing is still necessary
          for:
          <br />
          <br />
          <ul>
            <li>Compliance with a legal obligation of the Data Controller</li>
            <li>Archiving purposes of the Data Controller</li>
            <li>Protection of the legitimate interests of the Data Controller</li>
            <li>Establishment, exercise, or defense of legal claims of the Data Controller</li>
          </ul>
        </li>
        <li>
          <strong>The right to object to processing under Article 21 of the GDPR</strong>
          <br />
          You have the right to object to the processing of your personal data by the Data Controller, and the Data
          Controller will no longer process such data if: <br />
          <br />
          <ul>
            <li>
              The processing is necessary for the performance of a task carried out in the public interest or in the
              exercise of official authority vested in the controller, and the controller does not demonstrate
              compelling legitimate grounds for the processing which override your rights and freedoms
            </li>
            <li>The personal data are processed for direct marketing purposes</li>
          </ul>
        </li>
        <li>
          <strong>Right to data portability under Article 20 of the GDPR</strong>
          <br />
          You have the right to receive your personal data, which you have provided to the Data Controller, in a
          structured, commonly used, and machine-readable format, and have the right to transmit those data to another
          controller without hindrance from the Data Controller. This right applies in cases where your data are
          processed based on consent or contract and the processing is carried out by automated means. Your personal
          data will be provided to you in a structured, commonly used, and machine-readable format.
        </li>
        <li>
          <strong>Right to restriction of processing of your personal data for reasons set out by law</strong>
          (e.g., because of the unlawful processing, inaccuracy of the data)
        </li>
        <li>
          <strong>
            Right to withdraw consent to processing in writing or electronically at the address or email of the Data
            Controller
          </strong>
          <br />
          Data subjects have the right to withdraw their consent to the processing of personal data. This applies
          provided that the data subject's personal data are processed based on their consent.
        </li>
        <li>
          <strong>Right to lodge a complaint with the relevant supervisory authority</strong>
        </li>
      </ul>
      <p>
        If you believe that the processing of your personal data has violated or is violating the GDPR, you have, among
        other things, the right to lodge a complaint with the relevant supervisory authority, which is the Office for
        Personal Data Protection.
      </p>
      <p>
        If you are not given the opportunity to exercise any of your above-mentioned rights, you have the right to
        object.
      </p>
      <p><b>How can individual rights be exercised?</b></p>
      <p>
        All matters concerning the processing of your personal data can be addressed directly to the Data Controller,
        whom you can contact by any of the following means:
      </p>
      <ul>
        <li>By email at: <a href="mailto:jana.jakubcova@uced.cz">jana.jakubcova&#64;uced.cz</a></li>
        <li>In writing at the address: UCED s.r.o., AC Piano, Lidická 2331/6a, 370 01 České Budějovice</li>
      </ul>
      <p>
        Requests must have your signature officially verified. You can also exercise your rights in person through any
        employee of the Company.
      </p>
      <p>
        Your request will be processed without undue delay, but no later than one month. In exceptional cases,
        especially due to the complexity of your request, we are entitled to extend this period by another two months.
        Of course, we will inform you of such a possible extension and its reasons.
      </p>
    </section>`,
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyEnComponent {}
