import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import localeCs from '@angular/common/locales/cs';
import { registerLocaleData } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '../model/languages.model';
import { LoggingService } from './logging.service';

registerLocaleData(localeCs, 'cs');

export function getCurentLocale(): string {
  return localStorage.getItem('czone_lang') ?? 'cs';
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  languages$: BehaviorSubject<Language[]> = new BehaviorSubject(Language.getAll());
  selectedLanguage$: BehaviorSubject<string> = new BehaviorSubject(
    localStorage.getItem('czone_lang') ?? Language.getDefault().value
  );

  constructor(private translocoService: TranslocoService, private loggingService: LoggingService) {
    this.selectedLanguage$.pipe(tap((language: string) => this.saveToStorage(language))).subscribe({
      next: (language: string) => {
        this.loggingService.debug('selectedLanguage$.next', language);
      },
      error: (error: Error) => {
        this.loggingService.error('selectedLanguage$.error', error);
      },
    });
  }

  setLanguage(language: string): void {
    this.selectedLanguage$.next(language);
    // TODO
    location.reload();
  }

  private saveToStorage(language: string): void {
    this.translocoService.setActiveLang(language);
    localStorage.setItem('czone_lang', language);
  }
}
