export type EnergyProfileNames = 'aPlus' | 'aMinus' | 'rcPlus' | 'rcMinus' | 'riMinus' | 'riPlus';

export class EnergyProfiles {
  static readonly aPlus: EnergyProfiles = {
    value: 'aPlus',
    shortName: 'A+ [kWh]',
    displayName: '',
    translationId: 'energyProfiles.aPlus',
  };

  static readonly aMinus: EnergyProfiles = {
    value: 'aMinus',
    shortName: 'A- [kWh]',
    displayName: '',
    translationId: 'energyProfiles.aMinus',
  };

  static readonly rcPlus: EnergyProfiles = {
    value: 'rcPlus',
    shortName: 'Rc+ [kVArh]',
    displayName: '',
    translationId: 'energyProfiles.rcPlus',
  };

  static readonly rcMinus: EnergyProfiles = {
    value: 'rcMinus',
    shortName: 'Rc- [kVArh]',
    displayName: '',
    translationId: 'energyProfiles.rcMinus',
  };

  static readonly riMinus: EnergyProfiles = {
    value: 'riMinus',
    shortName: 'Ri- [kVArh]',
    displayName: '',
    translationId: 'energyProfiles.riMinus',
  };

  static readonly riPlus: EnergyProfiles = {
    value: 'riPlus',
    shortName: 'Ri+ [kVArh]',
    displayName: '',
    translationId: 'energyProfiles.riPlus',
  };

  static readonly unknown: EnergyProfiles = {
    displayName: 'Neznámá hodnota',
    shortName: 'unknown',
    translationId: 'energyProfiles.unknown',
  };

  readonly value?: 'aPlus' | 'aMinus' | 'rcPlus' | 'rcMinus' | 'riPlus' | 'riMinus';
  readonly displayName?: string;
  readonly shortName!: string;
  readonly translationId!: string;

  static getAll(): EnergyProfiles[] {
    return [this.aMinus, this.aPlus, this.rcMinus, this.rcPlus, this.riMinus, this.riPlus];
  }

  static getConsumption(): EnergyProfiles[] {
    return [this.aPlus, this.rcPlus, this.riPlus];
  }

  static getSupply(): EnergyProfiles[] {
    return [this.aMinus, this.rcMinus, this.riMinus];
  }

  static getProfileById(profileId: string): EnergyProfiles {
    return this.getAll().find((profile) => profile.value === profileId) ?? this.unknown;
  }
}
