<ng-container *ngIf="businessRelationshipDataService.activeRelationship$ | async as activeRelationship">
  <czone-main-header [drawer]="drawer" [drawerMode]="drawerMode"></czone-main-header>

  <mat-progress-bar
    *ngIf="!(loadingService.appLoaded$ | async)"
    class="loading-overlay"
    color="primary"
    mode="indeterminate"
  ></mat-progress-bar>

  <mat-drawer-container>
    <!-- Sidenav drawer -->
    <mat-drawer #drawer [opened]="true" [mode]="drawerMode">
      <czone-side-panel [activeRelationship]="activeRelationship"></czone-side-panel>
    </mat-drawer>

    <!-- Sidenav toggle -->
    <div class="drawer-container czone-flex-column">
      <!-- Main content -->
      <ng-container *ngIf="activeRelationship.id.length > 0">
        <div class="route-wrapper czone-flex-column">
          <router-outlet (activate)="loadingService.appLoaded$.next(true)"></router-outlet>
        </div>
      </ng-container>
    </div>
  </mat-drawer-container>
</ng-container>
