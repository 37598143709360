import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LegalEntityPipe } from './pipes/legal-entity.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { ExportModePipe } from './pipes/export-mode.pipe';
import { ProfilePipe } from './pipes/profile.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatDialogModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
      colorScheme: ['#42FF00', '#42FF00', '#A7A7A7', '#A7A7A7', '#FF0000', '#FF0000', '#FF0000'],
    }),
  ],
  declarations: [LegalEntityPipe, ExportModePipe, ProfilePipe],
  exports: [LegalEntityPipe, ExportModePipe, ProfilePipe],
})
export class UtilsModule {}
