import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { PrimaryRoutes } from './routes';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { LegalInformationComponent } from './components/legal-information/legal-information.component';
import { provideTranslocoScope } from '@ngneat/transloco';
import { AppFeatureGuard } from '@client-zone/data-access';
import { ContactsComponent } from './components/contacts/contacts.component';

const routes: Routes = [
  {
    path: PrimaryRoutes.userInformation.path,
    loadChildren: () => import('@client-zone/user-information').then((m) => m.UserInformationModule),
    data: PrimaryRoutes.userInformation,
    canActivate: [MsalGuard, AppFeatureGuard],
    providers: [provideTranslocoScope('user-information')],
  },
  {
    path: PrimaryRoutes.electricityDistribution.path,
    loadChildren: () => import('@client-zone/electricity-distribution').then((m) => m.ElectricityDistributionModule),
    data: PrimaryRoutes.electricityDistribution,
    canActivate: [MsalGuard, AppFeatureGuard],
    providers: [provideTranslocoScope('electricity-distribution')],
  },
  {
    path: PrimaryRoutes.customerRequests.path,
    loadChildren: () => import('@client-zone/customer-requests').then((m) => m.CustomerRequestsModule),
    data: PrimaryRoutes.customerRequests,
    canActivate: [MsalGuard, AppFeatureGuard],
    providers: [provideTranslocoScope('customer-requests')],
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    canActivate: [MsalGuard],
    providers: [provideTranslocoScope('privacy-policy')],
  },
  {
    path: 'legal-information',
    component: LegalInformationComponent,
    canActivate: [MsalGuard],
    providers: [provideTranslocoScope('legal-information')],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    canActivate: [MsalGuard],
    providers: [provideTranslocoScope('contacts')],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: PrimaryRoutes.defaultRoute().path,
  },
  {
    path: '**',
    redirectTo: PrimaryRoutes.defaultRoute().path,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
