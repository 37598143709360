import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(public logger: NGXLogger) {}

  trace(from: string, message: string | number | Error | unknown): void {
    this.logger.trace(`[${from}]: ${message}`);
  }

  debug(from: string, message: string | number | Error | unknown): void {
    this.logger.debug(`[${from}]: ${message}`);
  }

  info(from: string, message: string | number | Error | unknown): void {
    this.logger.info(`[${from}]: ${message}`);
  }

  warn(from: string, message: string | number | Error | unknown): void {
    this.logger.warn(`[${from}]: ${message}`);
  }

  error(from: string, message: string | number | Error | unknown): void {
    this.logger.error(`[${from}]: ${message}`);
  }

  fatal(from: string, message: string | number | Error | unknown): void {
    this.logger.fatal(`[${from}]: ${message}`);
  }
}
